import React, { useState } from 'react';
import club from '../../assets/images/me/club.png';
function PricingHomeTwo() {
    const [toggleSwitch, setSwitchValue] = useState(true);
    const handleSwitch = (e) => {
        e.preventDefault();
        setSwitchValue(!toggleSwitch);
    };
    return (
        <>
            <section className="appie-pricing-2-area pb-100" id='pricing'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Simple, affordable and multi-currency pricing for all in Africa</h3>
                                <p>
                                No Hidden Fees. One Time Payment.
                                </p>
                                <div className="appie-pricing-tab-btn">
                                    <ul
                                        className="nav nav-pills mb-3"
                                        id="pills-tab"
                                        role="tablist"
                                    >
                                        <li className="nav-item" role="presentation">
                                            <a
                                                onClick={(e) => handleSwitch(e)}
                                                className={`nav-link  ${
                                                    toggleSwitch ? 'active' : ''
                                                }`}
                                                href="#"
                                                type="button"
                                                role="tab"
                                            >
                                                Buy Regular
                                            </a>
                                        </li>
                                        <li
                                            className={`nav-item ${toggleSwitch ? 'on' : 'off'}`}
                                            role="presentation"
                                        >
                                            <a
                                                onClick={(e) => handleSwitch(e)}
                                                className={`nav-link  ${
                                                    toggleSwitch === false ? 'active' : ''
                                                }`}
                                                href="#"
                                                type="button"
                                                role="tab"
                                            >
                                                SAT Success Club Membership 
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="tab-content" id="pills-tabContent">
                                <div
                                    className={`tab-pane fade ${toggleSwitch ? 'active show' : ''}`}
                                    id="pills-home"
                                    role="tabpanel"
                                    aria-labelledby="pills-home-tab"
                                >
                                    <div className="row justify-content-center">
                                        <div className="col-lg-4 col-md-6">
                                            <div
                                                className="
                        pricing-one__single pricing-one__single_2
                        wow
                        animated
                        fadeInLeft
                      "
                                            >
                                                <div className="pricig-heading">
                                                    <h6>Fresh-</h6>
                                                    <div className="price-range">
                                                    
                                                        {/* <sup>$</sup> <span>1/</span><sup><del>N</del></sup> <span>750/</span> <sup><del>R</del></sup> <span>30</span> */}
                                                        <sup><del>N</del></sup> <span>750</span>
                                                        <p>Get your 7 Day trial plan going</p>
                                                    </div>
                                                    <p>After 7 Days you pay $39 for 6 Months of Unlimited Access.</p>
                                                </div>
                                                <div className="pricig-body">
                                                    <b>Everything You Get in 7 Days:</b>
                                                    <ul>
                                                        <li>
                                                            <i className="fal fa-check" /> Award Winning SAT Prep - get 200-point score increase, guaranteed. ($2,000+ value)
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" />Parent/Guardian Dashboard so you track progress and strengths/weaknesses. ($199 value)
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" /> Proctored Mock Tests so you can practice under exam-like conditions. ($69 value)
                                                        </li>
                                                        <li><i className="fal fa-check" />Predictive Readiness Score so you know when you're ready to take the exam. ($69 value)</li>
                                                        <li><i className="fal fa-check" />Over 700 College Knowledge and Career Questions so you can feel confident starting the next major chapter of your life. ($99 value)</li>
                                                        <li><i className="fal fa-check" />7+ Bonus College Readiness Skills and Academic Courses including Algebra, Biology, Chemistry, AP US History and more. ($299 value)</li>
                                                        <li>  <i className="fal fa-check" />Total Value: $2675+ . You Save: Thousands of dollars!</li>
                                                    </ul>
                                                    <div className="pricing-btn mt-35">
                                                        <a className="main-btn" href="#">
                                                            Choose plan
                                                        </a>
                                                        <div className="pricing-rebon">
                                                        <span>Trial Plan</span>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div
                                                className="
                        pricing-one__single pricing-one__single_2
                        active
                        wow
                        animated
                        fadeInUp
                      "
                                            >
                                                <div className="pricig-heading">
                                                    <h6>Sweet- </h6>
                                                    <div className="price-range">
                                                    <sup><del>N</del></sup> <span>29,250</span>
                                                         {/* <p>/month</p> */}
                                                    </div>
                                                    <p>Bumper 6-month access</p>
                                                </div>
                                                <div className="pricig-body">
                                                    <b>More for less, 6 month access</b>
                                                    <ul>
                                                        <li>
                                                            <i className="fal fa-check" />Award Winning SAT Prep - get 200-point score increase, guaranteed. ($2,000+ value)
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" /> Parent/Guardian Dashboard so you track progress and strengths/weaknesses. ($199 value)
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" /> Proctored Mock Tests so you can practice under exam-like conditions. ($69 value)
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" />Predictive Readiness Score so you know when you're ready to take the exam. ($69 value)
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" />Over 700 College Knowledge and Career Questions so you can feel confident starting the next major chapter of your life. ($99 value)
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" />7+ Bonus College Readiness Skills and Academic Courses including Algebra, Biology, Chemistry, AP US History and more. ($299 value)
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" />Total Value: $2675+ . You Save: Thousands of dollars!
                                                        </li>
                                                    </ul>
                                                    <div className="pricing-btn mt-35">
                                                        <a className="main-btn" href="#">
                                                            Choose plan
                                                        </a>
                                                    </div>
                                                    <div className="pricing-rebon">
                                                        <span>Most Popular</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div
                                                className="
                        pricing-one__single pricing-one__single_2
                        item-2
                        wow
                        animated
                        fadeInRight
                      "
                                            >
                                                <div className="pricig-heading">
                                                    <h6>Juicy-</h6>
                                                    <div className="price-range">
                                                    <sup><del>N</del></sup> <span>52,500</span>
                                                        {/* <p>/month</p> */}
                                                    </div>
                                                    <p>Get your 7 Day trial plan going.</p>
                                                </div>
                                                <div className="pricig-body">
                                                    <b>More for less, 1 year access</b>
                                                    <ul>
                                                        <li>
                                                            <i className="fal fa-check" />Award Winning SAT Prep - get 200-point score increase, guaranteed. ($2,000+ value)
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" /> Parent/Guardian Dashboard so you track progress and strengths/weaknesses. ($199 value)
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" />Proctored Mock Tests so you can practice under exam-like conditions. ($69 value)
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" />Predictive Readiness Score so you know when you're ready to take the exam. ($69 value)
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" />Over 700 College Knowledge and Career Questions so you can feel confident starting the next major chapter of your life. ($99 value)
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" />7+ Bonus College Readiness Skills and Academic Courses including Algebra, Biology, Chemistry, AP US History and more. ($299 value)
                                                        </li>
                                                        <li><i className="fal fa-check" />Total Value: $2675+ . You Save: Thousands of dollars!
                                                          </li>
                                                    </ul>
                                                    <div className="pricing-btn mt-35">
                                                        <a className="main-btn" href="#">
                                                            Choose plan
                                                        </a>
                                                    </div>
                                                    <div className="pricing-rebon">
                                                        <span>Annual Plan</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div
                                    className={`tab-pane fade ${
                                        toggleSwitch === false ? 'active show' : ''
                                    }`}
                                    id="pills-profile"
                                    role="tabpanel"
                                    aria-labelledby="pills-profile-tab"
                                >
                                    <div className="row justify-content-center">
                                    <img src={club} alt="" />
                                        <div className="col-lg-4 col-md-6">

                                            <div
                                                className="
                        pricing-one__single pricing-one__single_2
                        animated
                        fadeInLeft
                      "
                                            >
                                               
                                                <div className="pricig-heading">
                                                    <h6>Sat Success Club Membership</h6>
                                                    <div className="price-range">
                                                    <sup><del>N</del></sup> <span>45,000</span>
                                                        {/* <p>/Yearly</p> */}
                                                    </div>
                                                    <p>Annual membership plan</p>
                                                </div>
                                                <div className="pricig-body">
                                                    <ul>
                                                        <li>
                                                            <i className="fal fa-check" /> Discounted annual subscription plan
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" /> Discounted scholastic advisory plan
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" /> Free scholarship information
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" />Limited Time Free College and Career Bonuses
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" /> As may be subscribed:
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" />Award Winning SAT Prep - get 200-point score increase, guaranteed. ($2,000+ value)
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" /> Parent/Guardian Dashboard so you track progress and strengths/weaknesses. ($199 value)
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" />Proctored Mock Tests so you can practice under exam-like conditions. ($69 value)
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" />7+ Bonus College Readiness Skills and Academic Courses including Algebra, Biology, Chemistry, AP US History and more. ($299 value)
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" />Predictive Readiness Score so you know when you're ready to take the exam. ($69 value)
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" /> Over 700 College Knowledge and Career Questions so you can feel confident starting the next major chapter of your life. ($99 value)
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" /> Total Value: $2675+ .
                                                        </li> 
                                                         <li>
                                                            <i className="fal fa-check" />  You Save: Thousands of dollars!
                                                        </li> 
                                                    </ul>
                                                    <div className="pricing-btn mt-35">
                                                        <a className="main-btn" href="#">
                                                            Choose plan
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-4 col-md-6">
                                            <div
                                                className="
                        pricing-one__single pricing-one__single_2
                        active
                        animated
                        fadeInUp
                      "
                                            >
                                                <div className="pricig-heading">
                                                    <h6>Sweet</h6>
                                                    <div className="price-range">
                                                        <sup>$</sup> <span>116</span>
                                                        <p>/Yearly</p>
                                                    </div>
                                                    <p>Billed $276 per website annually.</p>
                                                </div>
                                                <div className="pricig-body">
                                                    <ul>
                                                        <li>
                                                            <i className="fal fa-check" /> 60-day
                                                            chat history
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" /> 50 GB
                                                            cloud storage
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" /> 24/7
                                                            Support
                                                        </li>
                                                    </ul>
                                                    <div className="pricing-btn mt-35">
                                                        <a className="main-btn" href="#">
                                                            Choose plan
                                                        </a>
                                                    </div>
                                                    <div className="pricing-rebon">
                                                        <span>Most Popular</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="col-lg-4 col-md-6">
                                            <div
                                                className="
                        pricing-one__single pricing-one__single_2
                        item-2
                        animated
                        fadeInRight
                      "
                                            >
                                                <div className="pricig-heading">
                                                    <h6>Juicy</h6>
                                                    <div className="price-range">
                                                    <sup><del>N</del></sup> <span>22,500</span>
                                                        {/* <p>/Yearly</p> */}
                                                    </div>
                                                    <p>Quarterly membership plan</p>
                                                </div>
                                                <div className="pricig-body">
                                                    <ul>
                                                    <li>
                                                            <i className="fal fa-check" />Discounted 6 month subscription plan
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" />Discounted scholastic advisory plan
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" /> Free scholarship information
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" /> Limited Time Free College and Career Bonuses
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" />As may be subscribed:
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" /> Award Winning SAT Prep - get 200-point score increase, guaranteed. ($2,000+ value)
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" /> Parent/Guardian Dashboard so you track progress and strengths/weaknesses. ($199 value)
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" />Proctored Mock Tests so you can practice under exam-like conditions. ($69 value)
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" /> Predictive Readiness Score so you know when you're ready to take the exam. ($69 value)
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" /> Over 700 College Knowledge and Career Questions so you can feel confident starting the next major chapter of your life. ($99 value)
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" />7+ Bonus College Readiness Skills and Academic Courses including Algebra, Biology, Chemistry, AP US History and more. ($299 value)
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" />Total Value: $2675+.
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" />You Save: Thousands of dollars!
                                                        </li>
                                                    </ul>
                                                    <div className="pricing-btn mt-35">
                                                        <a className="main-btn" href="#">
                                                            Choose plan
                                                        </a>
                                                    </div>
                                                    <div className="pricing-rebon">
                                                        <span>Popular Plan</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default PricingHomeTwo;
